import $ from 'jquery';

let $select = $( 'select[data-your-maps-filter-type="category"]' )

/**
 * Initialise all map components
 */
const init = () => {
  document.addEventListener( 'yourMapsLoaded', setCategory )
  document.addEventListener( 'yourMapsMessage', setCounter )
}

const setCategory = e => {
  let categoryName = getParameterByName( 'your-maps-category' ),
      category = ''

  if ( !categoryName )
    return

  window
    .your_maps
    .filter_tree
    .category
    .forEach( categoryObj => {
      if ( categoryObj.name.replace( /\s+/g, '-' ).toLowerCase() === categoryName.toLowerCase() )
        category = categoryObj.id
    } )

  if ( category && $select.find( `option[value="${ category }"]` ).length ) {
    setTimeout( () => {
      $select[ 0 ].value = category
      let event = new Event( 'change' )
      $select[ 0 ].dispatchEvent( event )
    }, 50 )
  }
}

const setCounter = e => {
  if ( e.detail && e.detail.totalResults )
    $( '.your-maps__filter-count' ).html( `${ e.detail.totalResults } Stockists` )
  else
    $( '.your-maps__filter-count' ).html( '' )
}

const getParameterByName = ( name, url ) => {
    if ( !url )
      url = window.location.href

    name = name.replace( /[\[\]]/g, '\\$&' )

    var regex   = new RegExp( '[?&]' + name + '(=([^&#]*)|&|#|$)' ),
        results = regex.exec( url )

    if ( !results )
      return null

    if ( !results[ 2 ] )
      return '';

    return decodeURIComponent( results[ 2 ].replace( /\+/g, ' ' ) );
}

export default () => init()

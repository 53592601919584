/*******************************************************
 * Copyright (C) 1996-2019 The Digital Embassy Pty Ltd admin@thedigitalembassy.com.au
 * These source files are proprietary, confidential and the Intellectual Property
 * of The Digital Embassy Pty Ltd Unauthorized copying, modifying and/or distributing
 * of source files, via any medium is strictly prohibited
 *******************************************************/

import $ from 'jquery'

const requireAll = requireContext => requireContext.keys().map( requireContext )

// Import component scripts
const components = requireAll( require.context( './components', true, /^.*\.js$/ ) )

const main = () => {
  // Run component scripts
  components.forEach( m => typeof m.default === 'function' && m.default() )

}

// Side Effects
$( document ).ready( () => main() )

import $ from "jquery";

const init = (el) => {
  /**
   * Initialise the calculator on DOM elements
   * @param {object} el raw HTML element
   */
  const $el = $(el),
    $input = $el.find('input[name="price"]'),
    $results = $el.find(".results"),
    $list = $el.find(".list");

  const formatInput = () => {
    /**
     * Format input value to be numerical, with commas and dollar symbol
     */
    let value = $input.val();

    if (value.charAt(0) === "$") value = value.substr(1);

    value = parseFloat(value.replace(/,/g, ""));

    if (!isNaN(value)) value = formatNumber(value);
    else value = "";

    $input.val(value);
  };

  const formatNumber = (number) => {
    /**
     * Format a number into a positive dollar amount with locale accurate spacings
     * @param {number} number
     * @returns {string}
     */
    return `$${Math.abs(number).toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })}`;
  };

  const clearInput = () => {
    /**
     * Clear input
     */
    $input.val("");
    setResults(null);
    $input.focus();
  };

  const handleBtnGroupStyle = () => {
    console.log("DOOO SOMETHING!!!");
    /**
     * Handle button group style
     */
    // hook into each radio button within the btn group, and check if they are checked, if they are, add a class to the parent label as active
    $(".btn-group input[type=radio]").each((i, el) => {
      const $el = $(el),
        $label = $el.closest("label");
      $el.prop("checked")
        ? $label.addClass("active")
        : $label.removeClass("active");
    });
  };

  // add event listener to .btn-group input[type=radio] to handle button group style
  $(".btn-group input[type=radio]").on("click", handleBtnGroupStyle);

  const setResults = (results) => {
    /**
     * Populate results panel
     * @param {object} results from getResults function
     */
    let inner = "";
    if (results) {
      inner += `<div style="display: flex; flex-direction: column; align-items: center">`;
      inner += `<h5 style="border-bottom: none;">Rental Rate</h5>`;
      inner += `<p>${formatNumber(results.weekly)} / week*</p>`;
      inner += `<p>or</p>`;
      inner += `<p>${formatNumber(results.monthly)} / month</p>`;
      inner += `<p style="font-size: 0.8em; margin-top: 0.5em;">*Return any time after ${$(
        'input[name="rental-period"]:checked'
      ).val()} months</p>`;
      inner += `</div>`;
      // inner += `<div><div>Monthly Rental Rate</div><div>${formatNumber(
      //   results.monthly
      // )}</div></div>`;
      // inner += `<div style="border-bottom: 1px solid #f78e1e"><div>Weekly Rental Rate</div><div>${formatNumber(
      //   results.weekly
      // )}</div></div>`;
      inner += `<h5>Buy Me At</h5>`;
      inner += `<div><div>Buy me at 1 month for</div><div>${formatNumber(
        results.buy_1
      )}</div></div>`;
      inner += `<div><div>Buy me at 6 months for</div><div>${formatNumber(
        results.buy_6
      )}</div></div>`;
      inner += `<div><div>Buy me at 12 months for</div><div>${formatNumber(
        results.buy_12
      )}</div></div>`;
      inner += `<div><div>Buy me at 18 months for</div><div>${formatNumber(
        results.buy_18
      )}</div></div>`;
      inner += `<div><div>Buy me at 36 months for</div><div>${formatNumber(
        results.buy_36
      )}</div></div>`;
    }
    $list.html(inner);
    if (results) $results.show();
    else $results.hide();
  };

  const compute = (e) => {
    /**
     * Run the calculator on the input
     * @param {object} e event
     * @returns {boolean}
     */
    e.preventDefault();
    formatInput();
    const value = $input.val(),
      results = value ? getResults(value) : null;

    setResults(results);
    return false;
  };

  $el.on("click", ".clear", clearInput);
  $el.on("click", ".button", compute);
  $el.on("change", 'input[name="rental-period"]', compute);
};

const getResults = (productPriceString) => {
  /**
   * Compute values based on Studio 19's supplied formulae
   * @param {number} amount
   * @returns {object}
   */

  const INTEREST_RATE_6 = 0.061;
  const INTEREST_RATE_12 = 0.051;
  const INTEREST_RATE_36 = 0.042;
  let productPrice = parseFloat(
    productPriceString.replace(/^\$/, "").replaceAll(",", "")
  );

  $period = $('input[name="rental-period"]:checked').val();
  $("#month-selected").text($period);
  switch ($period) {
    case "6":
      return {
        monthly: Math.max(1, Math.floor(productPrice * INTEREST_RATE_6)),
        weekly:
          Math.ceil(((productPrice * INTEREST_RATE_6 * 12) / 52) * 100) / 100,
        buy_1: Math.max(1, parseInt(productPrice * 0.99)),
        buy_6: Math.max(1, parseInt(productPrice * 0.925)),
        buy_12: Math.max(1, parseInt(productPrice * 0.814)),
        buy_18: Math.max(1, parseInt(productPrice * 0.667)),
        buy_36: Math.max(1, parseInt(productPrice * 0.038)),
      };
    case "12":
      return {
        monthly: Math.max(1, Math.floor(productPrice * INTEREST_RATE_12)),
        weekly:
          Math.ceil(((productPrice * INTEREST_RATE_12 * 12) / 52) * 100) / 100,
        buy_1: Math.max(1, parseInt(productPrice * 0.99)),
        buy_6: Math.max(1, parseInt(productPrice * 0.925)),
        buy_12: Math.max(1, parseInt(productPrice * 0.814)),
        buy_18: Math.max(1, parseInt(productPrice * 0.667)),
        buy_36: Math.max(1, parseInt(productPrice * 0.038)),
      };
    case "36":
      return {
        monthly: Math.max(1, Math.floor(productPrice * INTEREST_RATE_36)),
        weekly:
          Math.ceil(((productPrice * INTEREST_RATE_36 * 12) / 52) * 100) / 100,
        buy_1: Math.max(1, parseInt(productPrice * 0.99)),
        buy_6: Math.max(1, parseInt(productPrice * 0.925)),
        buy_12: Math.max(1, parseInt(productPrice * 0.814)),
        buy_18: Math.max(1, parseInt(productPrice * 0.667)),
        buy_36: Math.max(1, parseInt(productPrice * 0.038)),
      };
    default:
      break;
  }
  console.log($period);
};

export default () => $(".studio-19-calculator").each((i, el) => init(el));
